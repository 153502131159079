import React from "react";

// import img3 from "../img/img3.jpg";

const Header = () => {
	return (
		<header>
			<div className="img1"></div>
			<div className="img2"></div>
		</header>
	);
};

export default Header;
